import { Directive, HostListener } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[blurOnClick]'
})
export class BlurOnClickDirective {

  @HostListener('click')
  onClick(): void {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  }
}
