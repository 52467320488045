import { DestroyRef, Directive, ElementRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { fromEvent } from 'rxjs';

@Directive({
  selector: '[inputTrim]',
  standalone: true,

})
export class InputTrimDirective {
  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private ngControl: NgControl,
    private el: ElementRef
  ) {
    /**
     * Overwrite our value accessor to trim spaces
     */
    this.trimValueAccessor(this.ngControl.valueAccessor);

    /**
     * Subscripe to blur event to do a final trim on our control value to get rid of trailing spaces
     */
    fromEvent(this.el.nativeElement, 'blur')
      .pipe(
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.el.nativeElement.value = this.el.nativeElement.value.trim();
      });
  }

  /**
   * @description this function replaces the valuesAccessor to works on change events and trims the control value, but only
   *  removes leadings spaces on the input value.
   * @param valueAccessor
   */
  trimValueAccessor(valueAccessor: ControlValueAccessor) {
    const original = valueAccessor.registerOnChange;

    valueAccessor.registerOnChange = (fn: (_: unknown) => void) => {
      return original.call(valueAccessor, (value: unknown) => {
        // remove leading spaces only
        const inputValue = typeof value === 'string' ? value.replace(/\s*([a-zA-Z0-9_\s-]+)$/, '$1') : value;
        this.el.nativeElement.value = inputValue;

        // trim leading and trailing spaces
        const controlValue = typeof value === 'string' ? value.trim() : value;

        return fn(controlValue);
      });
    };
  }
}