import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BlurOnClickDirective } from '@resparke/directives';

@Component({
  selector: 'app-submit-button',
  templateUrl: './submit-button.component.html',
  styleUrls: ['./submit-button.component.scss'],
  standalone: true,
  imports: [MatButtonModule, MatProgressSpinnerModule, NgTemplateOutlet, NgClass, BlurOnClickDirective],
})
export class SubmitButtonComponent implements OnInit {

  @Output() onClick = new EventEmitter();
  @Input() color = 'primary';
  @Input() tabindex?: string;
  @Input() spinnerPosition?: 'last' | 'start';
  @Input() class?: string;
  @Input() isLoading: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() type = 'flat';

  constructor() { }

  ngOnInit(): void {
  }

  doClick() {
    this.onClick.emit(true);
  }
}