import { Directive } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Directive({
  selector: '[numbers]',
  standalone: true,
})
export class NumbersInputDirective {
  constructor(private ngControl: NgControl) {
    trimValueAccessor(ngControl.valueAccessor)
  }
}

function trimValueAccessor(valueAccessor: ControlValueAccessor) {
  const original = valueAccessor.registerOnChange;

  valueAccessor.registerOnChange = (fn: (_: unknown) => void) => {
    return original.call(valueAccessor, (value: unknown) => {
      if (typeof value === 'string') {
        const val = value.replace(/[^0-9]/g, '');
        valueAccessor.writeValue(val);
        return fn(val);
      } else {
        return fn(value);
      }
    });
  };
}